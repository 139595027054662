@media (max-width: 950px) {

    .background-preview {
        width: 100%;
        height: 100%;
    }
    
    .background-preview .wrapper-preview {
        width: 100%;
        height: 100%;
        padding: 30px;
        background-color: #ffffff;
        overflow-y: scroll;
    }
    
    .background-preview .wrapper-preview .container-date {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .background-preview .wrapper-preview .container-date .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0px 0px 20px 0px;
    }

    .background-preview .wrapper-preview h2 {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #838383;
        user-select: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
    }
    
    .background-preview .wrapper-preview h2::before, 
    .background-preview .wrapper-preview h2::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ccc; 
        margin: 0 10px; 
    }

    .background-preview .wrapper-preview h3 {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #838383;
        user-select: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
    }
    
    .background-preview .wrapper-preview h3::before, 
    .background-preview .wrapper-preview h3::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ccc; 
        margin: 0 10px; 
    }

    .background-preview .wrapper-preview .container-iban {
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 30px;
        border: 1px solid #c4c4c4;
        background-color: #f7f7f7;
    }

    .background-preview .wrapper-preview .container-iban h4 {
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }
    
    .background-preview .wrapper-preview .container-iban p {
        font-size: 15px;
        font-weight: 600;
        margin: 3px 0;
        display: flex;
        flex-direction: column;
    }
    
    .background-preview .wrapper-preview .container-iban p span {
        font-weight: 400;
        font-size: 15px;
    }
    
    .background-preview .wrapper-preview .container-iban .copy-iban {
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px;
        cursor: pointer;
    }
}

@media (min-width: 950px) {

    .background-preview {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 75px;
        left: 0;
        background-color: #f0f0f0;
        overflow-y: scroll;
    }
    
    .background-preview .wrapper-preview {
        position: relative;
        max-width: 650px;
        height: auto;
        margin: 150px auto 200px auto;
        padding: 30px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
    }
    
    .background-preview .wrapper-preview .container-date {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .background-preview .wrapper-preview h2 {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin: 30px 0px 10px 0px;
        color: #838383;
        user-select: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
    }
    
    .background-preview .wrapper-preview h2::before, 
    .background-preview .wrapper-preview h2::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ccc; 
        margin: 0 10px; 
    }

    .background-preview .wrapper-preview h3 {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #838383;
        user-select: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
    }
    
    .background-preview .wrapper-preview h3::before, 
    .background-preview .wrapper-preview h3::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ccc; 
        margin: 0 10px; 
    }

    .background-preview .wrapper-preview .container-iban {
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 30px;
        border: 1px solid #c4c4c4;
        background-color: #f7f7f7;
    }

    .background-preview .wrapper-preview .container-iban h4 {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        color: #333;
    }
    
    .background-preview .wrapper-preview .container-iban p {
        font-size: 15px;
        font-weight: 600;
        margin: 3px 0;
    }
    
    .background-preview .wrapper-preview .container-iban p span {
        font-weight: 400;
    }
    
    .background-preview .wrapper-preview .container-iban .copy-iban {
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px;
        cursor: pointer;
    }

    .background-preview .wrapper-preview .container-date .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        margin: 0px 0px 20px 0px;
    }
    
    .background-preview .wrapper-preview .container-amount {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .background-preview .wrapper-preview .container-amount .input-box {
        display: flex;
        flex-direction: column;
        width: 24%;
        margin: 0px 0px 10px 0px;
    }
}

.background-preview .wrapper-preview .container-back {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: start;
}

.background-preview .wrapper-preview .container-back button {
    width: auto;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.background-preview .wrapper-preview h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    color: #333;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.background-preview .wrapper-preview .input-box {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 0px;
}

.background-preview .wrapper-preview .input-box label {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.background-preview .wrapper-preview .input-box input {
    width: 100%;
    height: 35px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview .wrapper-preview .input-box textarea {
    width: 100%;
    height: 65px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    resize: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview .wrapper-preview .container-date .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview .wrapper-preview .container-progress {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.background-preview .wrapper-preview .progress-text {
    margin-bottom: 5px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    color: #333;
}

.background-preview .wrapper-preview .progress-wrapper {
    width: 100%;
    height: 15px;
    border: 1px solid #c4c4c4;
    background-color: #eeeeee;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;
    padding: 2px;
    box-sizing: border-box;
}

.background-preview .wrapper-preview .bar-progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.4s ease;
    border-radius: 4px;
}

.background-preview .wrapper-preview .progress-percentage {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    text-align: right; 
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}
