.wrapper-homemanagement {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 30px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: #ffffff;
    font-family: 'Poppins', sans-serif;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.wrapper-homemanagement .back-button {
    position: absolute;
    left: 30px;
    top: 20px;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.wrapper-homemanagement h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    text-align: center;
}

.wrapper-homemanagement h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    text-align: center;
    margin-top: 40px;
}

.wrapper-homemanagement .container-research {
    width: 100%;
    margin-top: 40px;
    margin-bottom: -20px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.wrapper-homemanagement .container-research .research {
    width: 50%;
    height: 100%;
    border: 10px;
    display: flex;
    justify-content: space-between;
}

.wrapper-homemanagement .container-research .research input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    padding-left: 10px; 
    padding-right: 10px; 
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #868686;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.wrapper-homemanagement .container-research .research span {
    width: 50px;
    height: 40px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #868686;
    color: #868686;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.wrapper-homemanagement .container-homemanagement {
    width: 100%;
    max-height: 300px;
    margin: 30px 0px 0px 0px;
    border-radius: 10px;
    overflow-y: scroll;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option {
    position: relative;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option .container-icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #c4c4c4;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option .container-icon .icon {
    font-size: 22px;
    color: #333;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option p {
    padding: 12px;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option .circle {
    margin-left: 12px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option .blue {
    color: rgb(74, 143, 247);
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option .green {
    color: rgb(90, 219, 78);
}

.wrapper-homemanagement .container-homemanagement #delete {
    background-color: #d45252;
    border: 1px solid #e44040;
    color: #ffffff;
}

.wrapper-homemanagement .container-homemanagement hr {
    margin: 10px 0;
    height: 1px; 
    background-color: #c7c7c7; 
    border: none; 
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color .3s;
}

.wrapper-homemanagement .container-homemanagement .homemanagement-option #delete-button {
    color: #ffffff;
}

.container-info-homemanagement {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: 2px solid #c4c4c4;
    border-radius: 10px;
    padding: 10px 15px;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.container-info-homemanagement p {
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
}

.container-info-homemanagement p span {
    color: #d45252;
    margin-right: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.container-info-homemanagement p .blue {
    color: rgb(74, 143, 247);
}

.container-info-homemanagement p .green {
    color: rgb(90, 219, 78);
}
