.background-users-management {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-users-management .wrapper-users-management {
    width: 100%;
    max-width: 700px;
    padding: 30px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: #ffffff;
}

.background-users-management .wrapper-users-management h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.background-users-management .wrapper-users-management .container-users {
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    overflow-y: scroll;
}

.background-users-management .wrapper-users-management .users-box {
    width: 100%;
    height: 45px;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
}

.background-users-management .wrapper-users-management .users-box .container-info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 80%;
}

.background-users-management .wrapper-users-management .users-box .container-info-box h2 {
    font-size: 15px;
    font-weight: 500;
}

.background-users-management .wrapper-users-management .users-box .container-info-box h3 {
    font-size: 15px;
    font-weight: 500;
}

.background-users-management .wrapper-users-management .users-box .container-info-box h4 {
    font-size: 15px;
    font-weight: 500;
}

.background-users-management .wrapper-users-management .users-box .container-btn-box {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: row;
    width: 20%;
}

.background-users-management .wrapper-users-management .users-box .container-btn-box button  {
    margin: 0 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.background-users-management .wrapper-users-management .users-box .container-btn-box button:nth-child(2) {
    color: #333;
}

.background-users-management .wrapper-users-management .users-box .container-btn-box button:nth-child(2) {
    color: #ec6565;
}

.background-users-management .wrapper-users-management .container-btn {
    width: 100%;
    height: auto;
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: end;
    align-items: center;
}

.background-users-management .wrapper-users-management .container-btn button {
    padding: 8px 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #68c970;
    border: none;
    outline: none;
    color: #ffffff;
    cursor: pointer;
}