@media (max-width: 900px) {

    .background-connection {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-connection .wrapper-connection {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: end;
        overflow: hidden;
    }

    .background-connection .wrapper-connection .container-left {
        position: relative;
        width: 100%;
        height: 100%; 
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .background-connection .wrapper-connection .container-left h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        text-align: center;
        font-weight: 500;
    }

    .background-connection .wrapper-connection .container-left h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .background-connection .wrapper-connection .container-left .input-box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 240px;
    }

    .background-connection .wrapper-connection .container-left .input-box span {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        color: #818181c0;
    }

    .background-connection .wrapper-connection .container-left .input-box input {
        width: 100%;
        height: 35px;
        background-color: #ecececc0;
        border: none;
        border-radius: 25px;
        outline: none;
        padding: 0px 40px; 
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }

    .background-connection .wrapper-connection .container-left .container-btn {
        width: 80%;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-connection .wrapper-connection .container-left .container-btn button {
        width: 120px;
        height: 34px;
        margin: 0 auto;
        background-color: #009eff;
        border: none;
        border-radius: 25px;
        outline: none;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        transition: background-color .3s;
    }

    .background-connection .wrapper-connection .container-left .container-btn button:hover {
        background-color: #0d7cd8;
    }

    .background-connection .wrapper-connection .container-right {
        display: none;
    }    
    
    .background-connection .wrapper-connection .icon-connexion {
        display: none;
        position: absolute;
        right: 20px;
        bottom: 10px;
        width: 180px;
    }

    .background-connection .wrapper-connection .logo {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 50px;
    }
}

@media (min-width: 900px) {
        
    .background-connection {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-connection .wrapper-connection {
        position: relative;
        width: 800px;
        height: 500px;
        background-color: #ffffff;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        align-items: end;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .background-connection .wrapper-connection .container-left {
        position: relative;
        width: 320px;
        margin-right: 30px;
        height: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: end;
    }

    .background-connection .wrapper-connection .container-left h1 {
        position: absolute;
        width: 80%;
        top: 23%;
        right: 0;
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        text-align: center;
        font-weight: 500;
    }

    .background-connection .wrapper-connection .container-left h2 {
        position: absolute;
        width: 80%;
        top: 30%;
        right: 0;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
    }


    .background-connection .wrapper-connection .container-left .input-box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .background-connection .wrapper-connection .container-left .input-box span {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        color: #818181c0;
    }

    .background-connection .wrapper-connection .container-left .input-box input {
        width: 100%;
        height: 35px;
        background-color: #ecececc0;
        border: none;
        border-radius: 25px;
        outline: none;
        padding: 0px 40px; 
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }

    .background-connection .wrapper-connection .container-left .container-btn {
        width: 80%;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-connection .wrapper-connection .container-left .container-btn button {
        width: 120px;
        height: 34px;
        margin: 0 auto;
        background-color: #009eff;
        border: none;
        border-radius: 25px;
        outline: none;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        transition: background-color .3s;
    }

    .background-connection .wrapper-connection .container-left .container-btn button:hover {
        background-color: #0d7cd8;
    }

    .background-connection .wrapper-connection .container-right {
        width: 450px;
        height: 100%; 
        display: flex;
        justify-content: end; 
        overflow: hidden; 
        position: relative; 
    }

    .background-connection .wrapper-connection .container-right img {
        position: absolute;
        left: 0; 
        width: auto;
        height: 100%; 
        object-fit: cover; 
    }

    .background-connection .wrapper-connection .icon-connexion {
        position: absolute;
        right: 20px;
        bottom: 10px;
        width: 250px;
    }

    .background-connection .wrapper-connection .logo {
        position: absolute;
        left: 20px;
        top: 18px;
        width: 50px;
    }
}

.background-connection .wrapper-connection .spinner-connection {
    border: 4px solid rgba(0, 153, 255, 0.253);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spinConnection 1s ease infinite;
}

@keyframes spinConnection {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
