@media (max-width: 600px) {

    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        z-index: 1000;
    }
        
    header ul {
        position: relative;
        max-width: 1440px;
        height: 100%;
        padding: 0px 20px;
        margin: 0 auto;
    }

    header ul .disconnect {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        outline: none;
        font-size: 30px;
        color: #333;
        cursor: pointer;
    }
}

@media (min-width: 600px) {

    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        z-index: 1000;
    }
    
    header ul {
        position: relative;
        max-width: 1440px;
        height: 100%;
        padding: 0px 40px;
        margin: 0 auto;
    }

    header ul .disconnect {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        outline: none;
        font-size: 30px;
        color: #333;
        cursor: pointer;
        transition: transform 0.3s ease; 
    }
    
    header ul .disconnect:hover {
        transform: translateY(-50%) scale(1.2); 
    }
    
}

header ul .admin-management {
    position: absolute;
    right: 90px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-size: 25px;
    color: #333;
    cursor: pointer;
    transition: transform 0.3s ease; 
}
    
header ul .admin-management:hover {
    transform: translateY(-50%) scale(1.2); 
}

header ul .container-logo {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

header ul .container-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}