@media (max-width: 950px) {

    .background-chat {
        width: 100%;
        overflow: hidden; 
        z-index: 9999;
    }

    .background-chat .wrapper-chat {
        width: 100%;
        height: 100vh;
        background-color: #ffffff;
        overflow: hidden; 
        display: flex;
        flex-direction: column;
        z-index: 9999;
    }

    .background-chat .wrapper-chat .container-header  {
        position: fixed;
        top: 0; 
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-bottom: 1px solid #c4c4c4;
        z-index: 10; 
    }
    
    .background-chat .wrapper-chat h1 {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333;
        overflow-y: hidden;
    }

    .background-chat .wrapper-chat .container-input-chat {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        flex-grow: 1; 
        overflow-y: hidden;
    }

    .background-chat .wrapper-chat .container-chat {
        width: 100%;
        flex-grow: 1; 
        margin-top: 70px;
        overflow-y: auto; 
    }

    .background-chat .wrapper-chat .container-input {
        width: 100%;
        height: 45px;
        padding: 40px 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #c4c4c4;
        background-color: #ffffff;
        z-index: 10; 
    }
}

@media (min-width: 950px) {

    .background-chat {
        width: 100%;
        overflow: hidden;
    }

    .background-chat .wrapper-chat {
        width: 100%;
        max-width: 800px;
        max-height: 730px;
        margin: 12% auto;
        padding: 30px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .background-chat .wrapper-chat .container-header  {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
    }
    
    .background-chat .wrapper-chat .container-header h1 {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333;
    }

    .background-chat .wrapper-chat .container-input-chat {
        width: 100%;
        padding: 5px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .background-chat .wrapper-chat .container-chat {
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column; 
        overflow-y: auto; 
        outline: none;
        padding-bottom: 10px; 
    }

    .background-chat .wrapper-chat .container-input {
        width: 100%;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        align-items: end;
    }
}

.background-chat .wrapper-chat .return {
    position: absolute;
    left: 20px;
    width: auto;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    outline: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.background-chat .wrapper-chat .container-chat .container-talk-staff {
    width: 100%;
    margin: 2px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.background-chat .wrapper-chat .container-chat .container-talk-myself {
    width: 100%;
    margin: 2px 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.background-chat .wrapper-chat .container-chat .chat-bubble {
    display: inline-block;
    max-width: 75%; 
    min-width: 80px;
    padding: 5px 8px;
    background-color: #3f3f3f;
    border-radius: 8px;
    color: #ffffff;
    word-wrap: break-word; 
}

.background-chat .wrapper-chat .container-chat .chat-bubble h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2.5px;
}

.background-chat .wrapper-chat .container-chat .chat-bubble p {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.background-chat .wrapper-chat .container-chat .chat-bubble .container-hours {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;  
    align-items: end; 
}

.background-chat .wrapper-chat .container-chat .chat-bubble .container-hours p {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: #e9e9e9;
}

.background-chat .wrapper-chat .container-chat .container-date {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin: 10px auto;
    padding: 3px 20px;
    background-color: #333333dc;
    border-radius: 10px;
    border: none;
    width: fit-content; 
}

.background-chat .wrapper-chat .container-chat .container-date p {
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
}

.background-chat .wrapper-chat .container-input textarea {
    flex-grow: 1;
    height: 45px;
    max-height: 145px;
    padding: 10px;
    outline: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    resize: none;
    margin-right: 3px;
}

.background-chat .wrapper-chat .container-input button {
    width: 45px; 
    height: 45px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 10px;
    background-color: rgb(39, 152, 218);
    color: #ffffff;
    cursor: pointer;
    flex-shrink: 0; 
    transition: background-color .3s;
}

.background-chat .wrapper-chat .container-input button:hover {
    background-color: rgb(25, 120, 175);
}