@media (max-width: 600px) {

    .wrapper-home {
        width: 100%;
        height: calc(100% - 70px);
        padding: 20px;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        font-family: 'Poppins', sans-serif;
    }

    .wrapper-home h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        text-align: center;
    }

    .wrapper-home h2 {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }

    .wrapper-home .container-home {
        width: 100%;
        margin: 50px 0px 0px 0px;
        border-radius: 10px;
    }

    .wrapper-home .container-home .home-option {
        position: relative;
        border: 1px solid #c4c4c4;
        border-radius: 6px;
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .wrapper-home .container-home .home-option .container-icon {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #c4c4c4;
    }
    
    .wrapper-home .container-home .home-option .container-icon .icon {
        font-size: 22px;
        color: #333;
    }

    .wrapper-home .container-home .home-option p {
        padding: 12px;
    }

    .wrapper-home .container-home .home-option button {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        transition: background-color .3s;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {

    .wrapper-home {
        width: 100%;
        max-width: 500px;
        height: auto;
        padding: 30px;
        margin: 170px auto;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
        font-family: 'Poppins', sans-serif;
    }

    .wrapper-home h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
    }

    .wrapper-home h2 {
        font-size: 16px;
        font-weight: 400;
    }

    .wrapper-home .container-home {
        width: 100%;
        margin: 50px 0px 0px 0px;
        border-radius: 10px;
    }

    .wrapper-home .container-home .home-option {
        position: relative;
        border: 1px solid #c4c4c4;
        border-radius: 6px;
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .wrapper-home .container-home .home-option .container-icon {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #c4c4c4;
    }
    
    .wrapper-home .container-home .home-option .container-icon .icon {
        font-size: 22px;
        color: #333;
    }

    .wrapper-home .container-home .home-option p {
        padding: 12px;
    }

    .wrapper-home .container-home .home-option button {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        transition: background-color .3s;
    }
}

@media (min-width: 1000px) {

    .wrapper-home {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 500px;
        height: auto;
        padding: 30px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
        font-family: 'Poppins', sans-serif;
    }

    .wrapper-home h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
    }

    .wrapper-home h2 {
        font-size: 16px;
        font-weight: 400;
    }

    .wrapper-home .container-home {
        width: 100%;
        margin: 50px 0px 0px 0px;
        border-radius: 10px;
    }

    .wrapper-home .container-home .home-option {
        position: relative;
        border: 1px solid #c4c4c4;
        border-radius: 6px;
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .wrapper-home .container-home .home-option .container-icon {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #c4c4c4;
    }
    
    .wrapper-home .container-home .home-option .container-icon .icon {
        font-size: 22px;
        color: #333;
    }

    .wrapper-home .container-home .home-option p {
        padding: 12px;
    }

    .wrapper-home .container-home .home-option button {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        transition: background-color .3s;
    }
}