@media (max-width: 500px) {

    .background-upload {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
    }
    
    .background-upload .wrapper-upload {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
    }

    .background-upload .wrapper-upload .container-back {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
    }
    
    .background-upload .wrapper-upload .container-upload {
        width: 100%;
        height: 300px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-upload .wrapper-upload .container-btn {
        position: absolute;
        bottom: 30px;
        right: 30px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
}


@media (min-width: 500px) and (max-width: 700px) {

    .background-upload {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
    }
    
    .background-upload .wrapper-upload {
        position: absolute;
        width: 90%;
        max-width: 600px;
        padding: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .background-upload .wrapper-upload .container-back {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
    }
        
    .background-upload .wrapper-upload .container-upload {
        width: 100%;
        height: 250px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-upload .wrapper-upload .container-btn {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
}

@media (min-width: 700px) {

    .background-upload {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        z-index: 1;
    }
    
    .background-upload .wrapper-upload {
        width: 100%;
        max-width: 600px;
        padding: 30px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .background-upload .wrapper-upload .container-back {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
    }
    
    .background-upload .wrapper-upload .container-upload {
        width: 100%;
        height: 250px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-upload .wrapper-upload .container-btn {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
}

.background-upload .wrapper-upload .container-back button {
    width: auto;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.background-upload .wrapper-upload h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.background-upload .wrapper-upload .container-upload p {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-upload .wrapper-upload h2 {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
    color: #333;
}

.background-upload .wrapper-upload .container-file {
    width: 100%;
    max-height: 200px;
    margin-top: 10px;
    padding: 5px 0px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.background-upload .wrapper-upload .container-file .file {
    position: relative;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
}

.background-upload .wrapper-upload .container-file .file p {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.background-upload .wrapper-upload .container-file .file .delete {
    position: absolute;
    right: 12.5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #db4f4f;
    cursor: pointer;
}

.background-upload .wrapper-upload .container-btn button {
    width: 150px;
    height: 35px;
    outline: none;
    border: none;
    background-color: #333;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
    transition: background-color .3s;
    cursor: pointer;
}

.background-upload .wrapper-upload .container-btn button:hover {
    background-color: #4d4d4d;
}

.background-upload .wrapper-upload .upload-progress {
    display: flex;
    align-items: center;
}

.background-upload .wrapper-upload .progress-text {
    margin-right: 8px; 
    font-size: 16px;
    font-weight: bold;
}

.background-upload .wrapper-upload .spinner-upload {
    border: 4px solid rgba(26, 26, 26, 0.473);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-left-color: #333;
    animation: spinUpload 1s ease infinite;
}

@keyframes spinUpload {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
