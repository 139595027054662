.background-upload-management {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.background-upload-management .wrapper-upload-management {
    width: 100%;
    max-width: 600px;
    padding: 30px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: #ffffff;
}

.background-upload-management .wrapper-upload-management .container-back {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
}

.background-upload-management .wrapper-upload-management .container-back button {
    width: auto;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.background-upload-management .wrapper-upload-management h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    color: #333;
}

.background-upload-management .wrapper-upload-management .container-upload-management {
    width: 100%;
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
}

.background-upload-management .wrapper-upload-management .container-upload-management .box-file {
    position: relative;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin: 5px 0;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.background-upload-management .wrapper-upload-management .container-upload-management .box-file h2 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.background-upload-management .wrapper-upload-management .container-upload-management .box-file button {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    color: #d85151;
    cursor: pointer;
}

.background-upload-management .wrapper-upload-management .container-upload-management .box-file button:nth-child(2) {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
}