.background-preview-create {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 75px;
    left: 0;
    background-color: #f0f0f0;
    overflow-y: scroll;
}

.background-preview-create .wrapper-preview-create {
    position: relative;
    max-width: 650px;
    height: auto;
    margin: 150px auto 200px auto;
    padding: 30px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: #ffffff;
}

.background-preview-create .wrapper-preview-create .container-back {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: start;
}

.background-preview-create .wrapper-preview-create .container-back button {
    width: auto;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.background-preview-create .wrapper-preview-create h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
    color: #333;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.background-preview-create .wrapper-preview-create .input-box {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 0px;
}

.background-preview-create .wrapper-preview-create .input-box label {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.background-preview-create .wrapper-preview-create .input-box input {
    width: 100%;
    height: 35px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .input-box select {
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    width: 100%;
    height: 35px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    background: none;
    background-color: white; 
    padding-right: 20px;
}

.background-preview-create .wrapper-preview-create .input-box select::after {
    content: ""; 
    display: none;
}

.background-preview-create .wrapper-preview-create .container-assigned {
    width: 100%;
    max-height: 80px;
    margin-top: -15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.background-preview-create .wrapper-preview-create .container-assigned .assigned-box {
    position: relative;
    width: 100%;
    margin: 2px 0;
    border: 1px solid #c4c4c4;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
}

.background-preview-create .wrapper-preview-create .container-assigned .assigned-box p {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .container-assigned .assigned-box .delete-assigned-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-size: 16px;
    color: #d85151;
    cursor: pointer;
}

.background-preview-create .wrapper-preview-create .checkbox-group input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.background-preview-create .wrapper-preview-create .checkbox-group label {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.background-preview-create .wrapper-preview-create .checkbox-group div {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
}

.background-preview-create .wrapper-preview-create .checkbox-group .container-social {
    margin: 0;
}

.background-preview-create .wrapper-preview-create .checkbox-group .social-group {
    flex-direction: column;
}

.background-preview-create .wrapper-preview-create .checkbox-group .social-input input[type="text"] {
    width: 100%;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .checkbox-group div {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
}

.background-preview-create .wrapper-preview-create .checkbox-group .container-other {
    margin: 0;
}

.background-preview-create .wrapper-preview-create .checkbox-group .other-group {
    flex-direction: column;
    margin: 0;
}

.background-preview-create .wrapper-preview-create .checkbox-group .other-input input[type="text"] {
    width: 100%;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .input-box textarea {
    width: 100%;
    height: 65px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    resize: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .container-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.background-preview-create .wrapper-preview-create .container-date .input-box {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin: 0px 0px 20px 0px;
}

.background-preview-create .wrapper-preview-create .container-date .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    outline: none;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.background-preview-create .wrapper-preview-create .container-btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: end;
    align-content: center;
}

.background-preview-create .wrapper-preview-create .container-btn button {
    margin-left: 10px;
    padding: 8px 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #68c970;
    border: none;
    outline: none;
    color: #ffffff;
    cursor: pointer;
}

.background-preview-create .wrapper-preview-create .container-btn button:nth-child(1) {
    background: #333;
}