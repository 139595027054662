.background-users-management-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(10px);
}

.wrapper-users-management-dialog {
    width: 600px;
    padding: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
}

.background-users-management-dialog .wrapper-users-management-dialog h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.background-users-management-dialog .wrapper-users-management-dialog .container-name,
.background-users-management-dialog .wrapper-users-management-dialog .container-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.background-users-management-dialog .wrapper-users-management-dialog .container-name .input-box,
.background-users-management-dialog .wrapper-users-management-dialog .container-info .input-box {
    width: 49.5%;
    display: flex;
    flex-direction: column;
}

.background-users-management-dialog .wrapper-users-management-dialog .input-box {
    width: 100%;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}

.background-users-management-dialog .wrapper-users-management-dialog .input-box label {
    font-size: 14px;
    font-weight: 500;
}

.background-users-management-dialog .wrapper-users-management-dialog .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}

.background-users-management-dialog .wrapper-users-management-dialog .input-box select {
    width: 100%;
    height: 35px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}

.background-users-management-dialog .wrapper-users-management-dialog .container-btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: end;
    align-content: center;
}

.background-users-management-dialog .wrapper-users-management-dialog .container-btn button {
    margin-left: 10px;
    padding: 8px 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #68c970;
    border: none;
    outline: none;
    color: #ffffff;
    cursor: pointer;
}

.background-users-management-dialog .wrapper-users-management-dialog .container-btn button:nth-child(1) {
    background: #333;
}